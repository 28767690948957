import React, { useEffect, useRef } from "react";
import Footer from "../components/footer";
import './serviceInnerPage.css';
import { Autoplay, Pagination } from "swiper/modules";
import {Swiper, SwiperSlide } from "swiper/react";
import 'swiper/swiper-bundle.css';
import { Helmet } from "react-helmet";
import Banner from "../components/banner";
import LetsCode from "../components/letscode";
import CardComponent from "../components/cardComponent";

const elegantFemale = `${process.env.PUBLIC_URL}/images/elegant-female.png`;
const AppMobile = `${process.env.PUBLIC_URL}/images/app-mobile.png`;
const Customsoftwaredevelopment = `${process.env.PUBLIC_URL}/images/icons/custom-software-development.png`;
const aspImage = `${process.env.PUBLIC_URL}/images/icons/asp.svg`;
const nodeImage = `${process.env.PUBLIC_URL}/images/icons/node.svg`;
const angularImage = `${process.env.PUBLIC_URL}/images/icons/angular.svg`;
const reactImage = `${process.env.PUBLIC_URL}/images/icons/react.svg`;
const pythonImage = `${process.env.PUBLIC_URL}/images/icons/python.svg`;
const javaImage = `${process.env.PUBLIC_URL}/images/icons/java.svg`;
const phpImage = `${process.env.PUBLIC_URL}/images/icons/php.svg`;
const vueImage = `${process.env.PUBLIC_URL}/images/icons/vue.svg`;
const clients = [
    `${process.env.PUBLIC_URL}/images/clients/66deg-client.png`,
    `${process.env.PUBLIC_URL}/images/clients/cabi.png`,
    `${process.env.PUBLIC_URL}/images/clients/pro-it.png`,
    `${process.env.PUBLIC_URL}/images/clients/scadea-client.png`,
    `${process.env.PUBLIC_URL}/images/clients/techolution-client.png`,
    `${process.env.PUBLIC_URL}/images/clients/next-loop.png`
  ];

  const  tracking = [
    {
        image: Customsoftwaredevelopment,
        title: 'Tailored Solutions',
        description: 'We focus on crafting tailored software solutions that address the specific challenges your business faces, ensuring optimal performance and effectiveness.'
      },
      {
        image: Customsoftwaredevelopment,
        title: 'User-Centric Design',
        description: 'We prioritize usability, that users have a seamless experience from the moment they interact with your application, maximizing satisfaction and engagement.'
      },
      {
        image: Customsoftwaredevelopment,
        title: 'Agile Development',
        description: 'This iterative process fosters collaboration and keeps you involved every step of the way, enhancing communication and ensuring your vision is realized effectively.'
      },
      {
        image: Customsoftwaredevelopment,
        title: 'Record of Success',
        description: 'With over two decades of experience, we have developed a proven track record in delivering successful software solutions across various industries.'
      },
]

const bannerDetails =
    {
        title: 'Our Services',
        subTitle: 'Implementation & Deployment',
        description: 'Transforming ideas into reality through seamless integration and efficient rollouts.'
    }

function  ImplementationAndDeployment() {

    const countersRef = useRef([]);

    const animateCounters = () => {
        countersRef.current.forEach(counter => {
            const target = +counter.getAttribute('data-target');
            let count = 0;
            const increment = Math.ceil(target / 200);

            const updateCounter = () => {
                if (count < target) {
                    count += increment;
                    counter.innerText = count > target ? target : count;
                    setTimeout(updateCounter, 10);
                } else {
                    counter.innerText = target;
                }
            };
            updateCounter();
        });
    };

    const scrollHandler = () => {
        const triggerHeight = window.innerHeight * 0.75;
        countersRef.current.forEach(counter => {
            const counterPosition = counter.getBoundingClientRect().top;
            if (counterPosition < triggerHeight) {
                animateCounters();
                window.removeEventListener('scroll', scrollHandler); // Ensure it runs only once
            }
        });
    };

    useEffect(() => {
        window.addEventListener('scroll', scrollHandler);
        return () => window.removeEventListener('scroll', scrollHandler); // Cleanup listener on unmount
    }, []);

    return(
        <>


<Helmet>
    <title>Implementation And Deployment | Stamens Software - A leading IT Company</title>
    </Helmet>

    <Banner details={bannerDetails} />

        <section className="trusted-id">
            <div className="container">
            <div className="swiper-wrapper">
            <Swiper
          modules={[Autoplay, Pagination]}
          spaceBetween={30}
          slidesPerView={5}
          autoplay={{
            delay: 1000,
            disableOnInteraction: true,
          }}
        >
          {clients.map((client, index) => (
            <SwiperSlide key={index} className="swiper-slide">
              <img src={client} alt={`Client ${index + 1}`} className="img-fluid" height="103" width="237" />
            </SwiperSlide>
          ))}
        </Swiper>
            </div>
            </div>
        </section>

        <section className="our-fetaures-id">
            <div className="container">
                <h2 className="head text-center"><span>Why Choose Us</span>
                    Our new clients working steps and more stategies
                </h2>
                <div className="row justify-content-center gy-4">
                    {[...Array(4)].map((_, index) => (
                        <div className="col-lg-3 col-md-6" key={index}>
                            <div className="part">
                                <span
                                    className="counter"
                                    data-target="1000"
                                    ref={el => countersRef.current[index] = el}
                                >
                                    0
                                </span>
                                <h3 className="small-head">Our Clients</h3>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>

     <LetsCode />

        <section className="tracking">
            <div className="container">
                <div className="row d-flex align-items-center">
                    <div className="col-lg-6">
                        <h3 className="head"><span>We provide</span> Tracking platform for App Development</h3>
                        <p className="tagline">Our dedicated team ensures a seamless transition from development to deployment, minimizing disruption and maximizing efficiency.</p>
                        <ul>
                            <li><i className="bi bi-check-circle-fill"></i>Customized Planning</li>
                            <li><i className="bi bi-check-circle-fill"></i>Development and Integration</li>
                            <li><i className="bi bi-check-circle-fill"></i>Testing and Quality Assurance</li>
                        </ul>
                    </div>
                    <div className="col-lg-6 text-center">
                        <img src={elegantFemale} alt="logo-icon image" className="img-fluid rounded" />
                    </div>
                </div>
                <div className="row d-flex align-items-center justify-content-center gy-4 mt-5">
                {tracking.map((card, index) => (
                <CardComponent key={index} cardProps={card} parentClass={'col-lg-3 col-md-6'} parentChildClass={'part'} childClassA={'icon'} childClassB={'content'} />
            ))}
                </div>
            </div>
        </section>

        <section className="our-company">
            <div className="container">
                <h4 className="head"><span>Why Stamens Software</span>We are one of the best IT companies?</h4>
                <div className="row gy-4">
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <div className="icon"><img src={Customsoftwaredevelopment} className="img-fluid" /></div>
                            <p>15+ Years in Industry</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <div className="icon"><img src={Customsoftwaredevelopment} className="img-fluid" /></div>
                            <p>Source Code Delivery to Clients</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <div className="icon"><img src={Customsoftwaredevelopment} alt="Custom Software Development" className="img-fluid" /></div>
                            <p>Microsoft 365, Azure</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <div className="icon"><img src={Customsoftwaredevelopment} alt="Custom Software Development" className="img-fluid" /></div>
                            <p>Less than 24 hrs Guaranteed Response</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <div className="icon"><img src={Customsoftwaredevelopment} alt="Custom Software Development" className="img-fluid" /></div>
                            <p>Certified Developers</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <div className="icon"><img src={Customsoftwaredevelopment} alt="Custom Software Development" className="img-fluid" /></div>
                            <p>Dedicated Project Manager</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <div className="icon"><img src={Customsoftwaredevelopment} alt="Custom Software Development" className="img-fluid" /></div>
                            <p>Clients: USA, UK, Australia, etc.</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <div className="icon"><img src={Customsoftwaredevelopment} alt="Custom Software Development" className="img-fluid" /></div>
                            <p>Handle Tight Deadlines</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="app-mobile-id">
            <div className="container">
                <div className="row d-flex align-items-center">
                    <div className="col-md-4">
                        <img src={AppMobile} alt="app-mobile" className="img-fluid" />
                    </div>
                    <div className="col-md-8">
                        <h3 className="head"> Partner with our client-centered team for top-quality software solutions </h3>
                        <p>Our team of skilled developers have years of experience in developing mobile apps for a range of platforms, including iOS, Android, and Windows. We use the latest tools and technologies to ensure that our apps are optimized for performance, security, and usability.</p>
                        <p>We create seamless and intuitive iOS applications that deliver top-notch user experiences for iPhones and iPads. Our Android apps are designed to perform exceptionally across a wide range of devices with an optimized user experience.</p>
                        <p>Using frameworks like Flutter and React Native, we build apps that work efficiently across both iOS and Android platforms. Our design team creates visually stunning, user-centered interfaces that engage users and enhance app usability.</p>            
                    </div>
                </div>
            </div>
        </section>

        <section className="technologie-id">
            <div className="container">
                <h3 className="head">
                    <span>Our Technologies</span>
                    All Technologies Software in stamens
                </h3>
                <div className="row gy-4">
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <img src={aspImage} alt="asp" className="img-fluid" />
                            <h4>.Net</h4>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <img src={nodeImage} alt="node" className="img-fluid" />
                            <h4>Node JS</h4>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <img src={angularImage} alt="angular" className="img-fluid" />
                            <h4>Angular JS</h4>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <img src={reactImage} alt="react" className="img-fluid" />
                            <h4>React JS</h4>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <img src={pythonImage} alt="python" className="img-fluid" />
                            <h4>Python</h4>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <img src={javaImage} alt="java" className="img-fluid" />
                            <h4>Java</h4>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <img src={phpImage} alt="php" className="img-fluid" />
                            <h4>PHP</h4>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <div className="icons">
                            <img src={vueImage} alt="vue" className="img-fluid" />
                            <h4>Vue JS</h4>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <Footer />
        </>
    )
}

export default ImplementationAndDeployment;