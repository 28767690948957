import { Button } from 'bootstrap';
import React from 'react'
import Breadcrumbs from './breadcrumb';

function Banner({details, descriptionClass}) {
  return (
    
<section className="custom-banner">
            <div className="container">
                <h1 className="banner-head text-center">
                    <span>{details.title}</span>
                    {details.subTitle}
                </h1>
                <p className={descriptionClass ? descriptionClass : "text-center"}>{details.description}</p>
                {details.button ?  <a class="call-btn" href="#" data-bs-toggle="modal" data-bs-target="#exampleModal">{details.buttonText}</a> : null}
                <Breadcrumbs />
            </div>
        </section>
  )
}

export default Banner;
