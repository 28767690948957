import React from 'react';

function ServiceComponent({serviceprops, parentClass, parentChildClass, childClassA, childClassB, h4}) {
  return (

    <div className={parentClass ? parentClass : "col-lg-4"}>
    <div className={parentChildClass ? parentChildClass : "part"}>
      {serviceprops.image ? 
      <img src={serviceprops.image} alt={serviceprops.heading} class="img-fluid" />
       : null}
      {h4 ? <h4 className={childClassA ? childClassA : "name"}>{serviceprops.heading}</h4> : <p className={childClassA ? childClassA : "name"}>{serviceprops.heading}</p>}
        
        <p>{serviceprops.para}</p>
    </div>
  </div>

    );

}

export default ServiceComponent;
