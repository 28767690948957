import React from 'react'
import Footer from '../components/footer';
import './mvp.css';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import { Autoplay, Navigation } from 'swiper/modules';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import ServiceComponent from '../components/servicecomponent';
import Banner from '../components/banner';

const customSoftwareDevelopment = `${process.env.PUBLIC_URL}/images/icons/custom-software-development.png`;
const angular = `${process.env.PUBLIC_URL}/images/tools/angular.svg`;
const bootstrap = `${process.env.PUBLIC_URL}/images/tools/bootstrap.svg`;
const css = `${process.env.PUBLIC_URL}/images/tools/css.svg`;
const html = `${process.env.PUBLIC_URL}/images/tools/html.svg`;
const javaScript = `${process.env.PUBLIC_URL}/images/tools/javaScript.svg`;
const materialUi = `${process.env.PUBLIC_URL}/images/tools/material-ui.svg`;
const reactJs = `${process.env.PUBLIC_URL}/images/tools/reactJs.svg`;
const typeScript = `${process.env.PUBLIC_URL}/images/tools/typeScript.svg`;
const vueJs = `${process.env.PUBLIC_URL}/images/tools/VueJs.svg`;
const dotNet = `${process.env.PUBLIC_URL}/images/tools/dotnet.svg`;
const java = `${process.env.PUBLIC_URL}/images/tools/java.svg`;
const python = `${process.env.PUBLIC_URL}/images/tools/python.svg`;
const nodejs = `${process.env.PUBLIC_URL}/images/tools/nodejs.svg`;
const php = `${process.env.PUBLIC_URL}/images/tools/php.svg`;
const ios = `${process.env.PUBLIC_URL}/images/tools/ios.svg`;
const android = `${process.env.PUBLIC_URL}/images/tools/android.svg`;
const xamarin = `${process.env.PUBLIC_URL}/images/tools/xamarin.svg`;
const cordova = `${process.env.PUBLIC_URL}/images/tools/cordova.svg`;
const pwa = `${process.env.PUBLIC_URL}/images/tools/pwa.svg`;
const reactNative = `${process.env.PUBLIC_URL}/images/tools/react-native.svg`;
const flutter = `${process.env.PUBLIC_URL}/images/tools/flutter.svg`;
const cplusplus = `${process.env.PUBLIC_URL}/images/tools/cplusplus.svg`;
const quasar = `${process.env.PUBLIC_URL}/images/tools/quasar-toolkit.svg`;
const csharp = `${process.env.PUBLIC_URL}/images/tools/csharp.svg`;
const wpf = `${process.env.PUBLIC_URL}/images/tools/wpf.svg`;
const obj = `${process.env.PUBLIC_URL}/images/tools/obj.svg`;
const sqlServer = `${process.env.PUBLIC_URL}/images/tools/sql-server.svg`;
const msql = `${process.env.PUBLIC_URL}/images/tools/msql.svg`;
const azuresqlDb = `${process.env.PUBLIC_URL}/images/tools/azure-sql-db.svg`;
const postgresql = `${process.env.PUBLIC_URL}/images/tools/postgresql.svg`;
const cassandra = `${process.env.PUBLIC_URL}/images/tools/cassandra.svg`;
const hive = `${process.env.PUBLIC_URL}/images/tools/hive.svg`;
const hBase = `${process.env.PUBLIC_URL}/images/tools/h-base.svg`;
const nifi = `${process.env.PUBLIC_URL}/images/tools/nifi.svg`;
const amazonSimpleStorage = `${process.env.PUBLIC_URL}/images/tools/amazon-simple-storage.svg`;
const amazonRedshift = `${process.env.PUBLIC_URL}/images/tools/amazon-redshift.svg`;
const amazonRb = `${process.env.PUBLIC_URL}/images/tools/amazon-accound-rb.svg`;
const amazonRds = `${process.env.PUBLIC_URL}/images/tools/amazon_rds.svg`;
const elastic = `${process.env.PUBLIC_URL}/images/tools/elastic.svg`;
const awsParnter = `${process.env.PUBLIC_URL}/images/tools/aws-parnter.svg`;
const azuredataLake = `${process.env.PUBLIC_URL}/images/tools/azure-data-lake.svg`;
const microsoft = `${process.env.PUBLIC_URL}/images/tools/mircrosoft.svg`;
const oracle = `${process.env.PUBLIC_URL}/images/tools/oracle.svg`;
const salesforce = `${process.env.PUBLIC_URL}/images/tools/salesforce.svg`;
const serviceNow = `${process.env.PUBLIC_URL}/images/tools/service-now.svg`;
const appMobile = `${process.env.PUBLIC_URL}/images/app-mobile.png`;
const bankingFinance = `${process.env.PUBLIC_URL}/images/industries/banking-and-finance.png`;
const educationElearning = `${process.env.PUBLIC_URL}/images/industries/education-and-elearning.png`;
const fintech = `${process.env.PUBLIC_URL}/images/industries/fintech.png`;
const healthcare = `${process.env.PUBLIC_URL}/images/industries/healthcare.png`;
const logisticsTransport = `${process.env.PUBLIC_URL}/images/industries/logistics-and-transport.png`;
const manufacturing = `${process.env.PUBLIC_URL}/images/industries/manufacturing.png`;
const mediaEntertainment = `${process.env.PUBLIC_URL}/images/industries/media-and-entertainment.png`;
const retailEcommerce = `${process.env.PUBLIC_URL}/images/industries/retail-and-ecommerce.png`;
const travelTourism = `${process.env.PUBLIC_URL}/images/industries/travel-and-tourism.png`;


const industries = [
  { img: healthcare, alt: "Healthcare", name: "Healthcare" },
  { img: travelTourism, alt: "Travel & Tourism", name: "Travel & Tourism" },
  { img: bankingFinance, alt: "Banking & Finance", name: "Banking & Finance" },
  { img: logisticsTransport, alt: "Logistics & Transport", name: "Logistics & Transport" },
  { img: retailEcommerce, alt: "Retail & Ecommerce", name: "Retail & Ecommerce" },
  { img: mediaEntertainment, alt: "Media & Entertainment", name: "Media & Entertainment" },
  { img: educationElearning, alt: "Education & E-Learning", name: "Education & E-Learning" },
  { img: fintech, alt: "FinTech", name: "FinTech" },
  { img: manufacturing, alt: "Manufacturing", name: "Manufacturing" },
];

const serviceCard = [
    {
        heading: 'MVP Consulting',
        para: 'Maximize the potential of your idea with our expert MVP consulting services. We help you clarify your vision, define essential features, and develop a strategic roadmap for Minimum Viable Product success. This ensures your MVP is well-positioned to meet market needs.',
    },
    {
        heading: 'Prototype Designing',
        para: 'Visualize and refine your concept with our prototype designing services. By creating interactive prototypes, we help you test and validate key features, gather user feedback, and make informed decisions before investing in full-scale development.',
    },
    {
        heading: 'Pilot MVP Development',
        para: 'Test your MVP in real-world scenarios with our pilot development service. This allows you to evaluate core functionalities, measure user engagement, and make necessary adjustments, ensuring your MVP is well-prepared for broader market launch.',
    },
    {
        heading: 'End-to-End Development',
        para: 'Streamline your development process with our end-to-end services. We manage every stage from initial planning to final launch, ensuring a cohesive and comprehensive approach that integrates design, development, and testing for a successful MVP.',
    },
    {
        heading: 'Dedicated Team',
        para: 'Benefit from a dedicated team focused solely on your MVP project. Our specialized experts work closely with you to ensure efficient development, addressing your unique requirements and delivering high-quality results tailored to your needs.',
    },
    {
        heading: 'Quality Assurance',
        para: 'Achieve a reliable and robust MVP with our rigorous quality assurance. We conduct thorough testing to identify and resolve issues early, ensuring your product performs well and meets the highest standards before it reaches the market.',
    },
]

const bannerDetails =
{
    title: 'Our Services',
    subTitle: 'MVP Development',
    description: 'Our company provides a complete range of MVP development services for clients ranging from startups to enterprises across the globe.'
}

function Mvp() {
  return (
   <>

<Helmet>
    <title>MVP | Development | Stamens Software-Services</title>
    </Helmet>

    <Banner details={bannerDetails} descriptionClass={'text-center fs-5'}/>

        <section class="mvp-service bg-white">
            <div class="container">
                <h2 class="head text-center">
                    <span>We Offer</span>
                    MVP Development Services 
                </h2>
                <p class="text-center fs-5 pb-4">With a focus on agile methodologies and user-centric design, our MVP Development Services provide strategic planning and execution to bring your vision to life. We turn ideas into viable products, ensuring your project’s success from inception to launch.</p>
                <div class="center-sec">
                    <div class="row justify-content-center gy-4">
                    {serviceCard.map((card, index) => (
               <ServiceComponent  key={index} serviceprops={card} />
            ))}
                    </div>
                </div>
            </div>
        </section>

        <section class="technologies-mvp">
            <div class="container">
                <h3 class="head text-center">
                    Tech Stack
                </h3>
                <p class="text-center fs-5 pb-4">Discover the powerful technologies we leverage to modernize your legacy systems and drive business innovation.</p>

                <ul class="nav nav-tabs justify-content-center" id="myTab" role="tablist">
                    <li class="nav-item" role="presentation">
                      <button class="nav-link active" id="techtab1-tab" data-bs-toggle="tab" data-bs-target="#techtab1-tab-pane" type="button" role="tab" aria-controls="techtab1-tab-pane" aria-selected="true">Frontend</button>
                    </li>
                    <li class="nav-item" role="presentation">
                      <button class="nav-link" id="techtab2-tab" data-bs-toggle="tab" data-bs-target="#techtab2-tab-pane" type="button" role="tab" aria-controls="techtab2-tab-pane" aria-selected="false">Backend</button>
                    </li>
                    <li class="nav-item" role="presentation">
                      <button class="nav-link" id="techtab3-tab" data-bs-toggle="tab" data-bs-target="#techtab3-tab-pane" type="button" role="tab" aria-controls="techtab3-tab-pane" aria-selected="false">Mobile</button>
                    </li>
                    <li class="nav-item" role="presentation">
                      <button class="nav-link" id="techtab4-tab" data-bs-toggle="tab" data-bs-target="#techtab4-tab-pane" type="button" role="tab" aria-controls="techtab4-tab-pane" aria-selected="false">Desktop</button>
                    </li>
                    <li class="nav-item" role="presentation">
                      <button class="nav-link" id="techtab5-tab" data-bs-toggle="tab" data-bs-target="#techtab5-tab-pane" type="button" role="tab" aria-controls="techtab5-tab-pane" aria-selected="false">Databases/data  Storages</button>
                    </li>
                    <li class="nav-item" role="presentation">
                      <button class="nav-link" id="techtab6-tab" data-bs-toggle="tab" data-bs-target="#techtab6-tab-pane" type="button" role="tab" aria-controls="techtab6-tab-pane" aria-selected="false">Cloud  Storages</button>
                    </li>
                    <li class="nav-item" role="presentation">
                      <button class="nav-link" id="techtab7-tab" data-bs-toggle="tab" data-bs-target="#techtab7-tab-pane" type="button" role="tab" aria-controls="techtab7-tab-pane" aria-selected="false">Strategic  Partnership</button>
                    </li>
                </ul>
                <div class="tab-content" id="myTabContent">
                    <div class="tab-pane fade show active" id="techtab1-tab-pane" role="tabpanel" aria-labelledby="techtab1-tab" tabindex="0">
                        <div class="tools">
                            <div class="tool">
                                <div class="image">
                                    <img src={html} alt="html" class="img-fluid" />
                                </div>
                                <p>HTML</p>
                            </div>
                            <div class="tool">
                                <div class="image">
                                    <img src={css} alt="css" class="img-fluid" />
                                </div>
                                <p>CSS</p>
                            </div>
                            <div class="tool">
                                <div class="image">
                                    <img src={javaScript} alt="javascript" class="img-fluid" />
                                </div>
                                <p>javascript</p>
                            </div>
                            <div class="tool">
                                <div class="image">
                                    <img src={typeScript} alt="Typescript" class="img-fluid" />
                                </div>
                                <p>Typescript</p>
                            </div>
                            <div class="tool">
                                <div class="image">
                                    <img src={reactJs} alt="React js" class="img-fluid" />
                                </div>
                                <p>React.js</p>
                            </div>
                            <div class="tool">
                                <div class="image">
                                    <img src={angular} alt="Angular" class="img-fluid" />
                                </div>
                                <p>Angular</p>
                            </div>
                            <div class="tool">
                                <div class="image">
                                    <img src={vueJs} alt="Vue js" class="img-fluid" />
                                </div>
                                <p>Vue.js</p>
                            </div>
                            <div class="tool">
                                <div class="image">
                                    <img src={materialUi} alt="Material-UI" class="img-fluid" />
                                </div>
                                <p>Material-UI</p>
                            </div>
                            <div class="tool">
                                <div class="image">
                                    <img src={bootstrap} alt="Bootstrap" class="img-fluid" />
                                </div>
                                <p>Bootstrap</p>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="techtab2-tab-pane" role="tabpanel" aria-labelledby="techtab2-tab" tabindex="0">
                        <div class="tools">
                            <div class="tool">
                                <div class="image">
                                    <img src={dotNet} alt="dotnet" class="img-fluid" />
                                </div>
                                <p>.Net</p>
                            </div>
                            <div class="tool">
                                <div class="image">
                                    <img src={java} alt="java" class="img-fluid" />
                                </div>
                                <p>Java</p>
                            </div>
                            <div class="tool">
                                <div class="image">
                                    <img src={python} alt="python" class="img-fluid" />
                                </div>
                                <p>Python</p>
                            </div>
                            <div class="tool">
                                <div class="image">
                                    <img src={nodejs} alt="nodejs" class="img-fluid" />
                                </div>
                                <p>Node js</p>
                            </div>
                            <div class="tool">
                                <div class="image">
                                    <img src={php} alt="php" class="img-fluid" />
                                </div>
                                <p>PHP</p>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="techtab3-tab-pane" role="tabpanel" aria-labelledby="techtab3-tab" tabindex="0">
                        <div class="tools">
                            <div class="tool">
                                <div class="image">
                                    <img src={ios} alt="ios" class="img-fluid" />
                                </div>
                                <p>IOS</p>
                            </div>
                            <div class="tool">
                                <div class="image">
                                    <img src={android} alt="android" class="img-fluid" />
                                </div>
                                <p>Android</p>
                            </div>
                            <div class="tool">
                                <div class="image">
                                    <img src={xamarin} alt="xamarin" class="img-fluid" />
                                </div>
                                <p>Xamarin</p>
                            </div>
                            <div class="tool">
                                <div class="image">
                                    <img src={cordova} alt="cordova" class="img-fluid" />
                                </div>
                                <p>Cordova</p>
                            </div>
                            <div class="tool">
                                <div class="image">
                                    <img src={pwa} alt="pwa" class="img-fluid" />
                                </div>
                                <p>PWA</p>
                            </div>
                            <div class="tool">
                                <div class="image">
                                    <img src={reactNative} alt="react-native" class="img-fluid" />
                                </div>
                                <p>React Native</p>
                            </div>
                            <div class="tool">
                                <div class="image">
                                    <img src={flutter} alt="flutter" class="img-fluid" />
                                </div>
                                <p>flutter</p>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="techtab4-tab-pane" role="tabpanel" aria-labelledby="techtab4-tab" tabindex="0">
                        <div class="tools">
                            <div class="tool">
                                <div class="image">
                                    <img src={cplusplus} alt="C++" class="img-fluid" />
                                </div>
                                <p>C++</p>
                            </div>
                            <div class="tool">
                                <div class="image">
                                    <img src={quasar} alt="quasar-toolkit" class="img-fluid" />
                                </div>
                                <p>Quasar Toolkit</p>
                            </div>
                            <div class="tool">
                                <div class="image">
                                    <img src={csharp} alt="csharp" class="img-fluid" />
                                </div>
                                <p>C#</p>
                            </div>
                            <div class="tool">
                                <div class="image">
                                    <img src={wpf} alt="wpf" class="img-fluid" />
                                </div>
                                <p>WPF</p>
                            </div>
                            <div class="tool">
                                <div class="image">
                                    <img src={obj} alt="obj" class="img-fluid" />
                                </div>
                                <p>OBJ-C</p>
                            </div>
                            <div class="tool">
                                <div class="image">
                                    <img src={python} alt="python" class="img-fluid" />
                                </div>
                                <p>Python</p>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="techtab5-tab-pane" role="tabpanel" aria-labelledby="techtab5-tab" tabindex="0">
                        <div class="tools">
                            <div class="tool">
                                <div class="image">
                                    <img src={sqlServer} alt="sql-server" class="img-fluid" />
                                </div>
                                <p>SQL Server</p>
                            </div>
                            <div class="tool">
                                <div class="image">
                                    <img src={msql} alt="msql" class="img-fluid" />
                                </div>
                                <p>MySQL</p>
                            </div>
                            <div class="tool">
                                <div class="image">
                                    <img src={azuresqlDb} alt="Azure SQL Database" class="img-fluid" />
                                </div>
                                <p>Azure SQL Database</p>
                            </div>
                            <div class="tool">
                                <div class="image">
                                    <img src={postgresql} alt="postgresql" class="img-fluid" />
                                </div>
                                <p>postgresql</p>
                            </div>
                            <div class="tool">
                                <div class="image">
                                    <img src={cassandra} alt="cassandra" class="img-fluid" />
                                </div>
                                <p>cassandra</p>
                            </div>
                            <div class="tool">
                                <div class="image">
                                    <img src={angular} alt="Angular" class="img-fluid" />
                                </div>
                                <p>Angular</p>
                            </div>
                            <div class="tool">
                                <div class="image">
                                    <img src={hive} alt="hive" class="img-fluid" />
                                </div>
                                <p>HIVE</p>
                            </div>
                            <div class="tool">
                                <div class="image">
                                    <img src={hBase} alt="h-base" class="img-fluid" />
                                </div>
                                <p>HBASE</p>
                            </div>
                            <div class="tool">
                                <div class="image">
                                    <img src={nifi} alt="nifi" class="img-fluid" />
                                </div>
                                <p>nifi</p>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="techtab6-tab-pane" role="tabpanel" aria-labelledby="techtab6-tab" tabindex="0">
                        <div class="tools">
                            <div class="tool">
                                <div class="image">
                                    <img src={amazonSimpleStorage} alt="amazon-simple-storage" class="img-fluid" />
                                </div>
                                <p>Amazon Simple Storage</p>
                            </div>
                            <div class="tool">
                                <div class="image">
                                    <img src={amazonRedshift} alt="amazon-redshift" class="img-fluid" />
                                </div>
                                <p>Amazon Redshift</p>
                            </div>
                            <div class="tool">
                                <div class="image">
                                    <img src={amazonRb} alt="amazon-accound-rb" class="img-fluid" />
                                </div>
                                <p>Amazon DocumentDB</p>
                            </div>
                            <div class="tool">
                                <div class="image">
                                    <img src={amazonRds} alt="amazon_rds" class="img-fluid" />
                                </div>
                                <p>Amazon RDS</p>
                            </div>
                            <div class="tool">
                                <div class="image">
                                    <img src={elastic} alt="elastic" class="img-fluid" />
                                </div>
                                <p>AWS Elasticache</p>
                            </div>
                            <div class="tool">
                                <div class="image">
                                    <img src={azuredataLake} alt="azure-data-lake" class="img-fluid" />
                                </div>
                                <p>Azure Data Lake</p>
                            </div>
                            <div class="tool">
                                <div class="image">
                                    <img src={azuresqlDb} alt="azure-sql-db" class="img-fluid" />
                                </div>
                                <p>Azure SQL DB</p>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="techtab7-tab-pane" role="tabpanel" aria-labelledby="techtab7-tab" tabindex="0">
                        <div class="tools">
                            <div class="tool">
                                <div class="image">
                                    <img src={microsoft} alt="mircrosoft" class="img-fluid" />
                                </div>
                                <p>Mircrosoft</p>
                            </div>
                            <div class="tool">
                                <div class="image">
                                    <img src={oracle} alt="oracle" class="img-fluid" />
                                </div>
                                <p>Oracle</p>
                            </div>
                            <div class="tool">
                                <div class="image">
                                    <img src={awsParnter} alt="aws-parnter" class="img-fluid" />
                                </div>
                                <p>AWS Parnter</p>
                            </div>
                            <div class="tool">
                                <div class="image">
                                    <img src={salesforce} alt="salesforce" class="img-fluid" />
                                </div>
                                <p>Sales Force</p>
                            </div>
                            <div class="tool">
                                <div class="image">
                                    <img src={serviceNow} alt="service-now" class="img-fluid" />
                                </div>
                                <p>Service Now</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="connect">
            <div class="container">
                <h3 class="head"><span>Let's Code</span> Your Success Story Together</h3>
                <p class="tagline col-md-9 mx-auto text-center fs-4 mb-5 d-block">Get in touch with our skilled developers to begin receiving the bestdesign and developmentservices for your ultimate website</p>
                <div class="btn-group">
                    <Link to="/contact"><i class="fa fa-clipboard-question"></i> Ask a Query</Link>
                    <a href="tel:+911203500848"><i class="fa fa-phone" aria-hidden="true"></i> Call Now</a>
                </div>
            </div>
        </section>

        <section class="process-mvp">
            <div class="container">
                <h3 class="head text-center">
                    Our MVP Development Process
                </h3>
                <p class="text-center fs-5 pb-4">We have a tried-and-tested process in place for developing time-efficient MVPs tailored to your specific needs. You can trust us to deliver results that exceed your expectations. Let’s have a look at our MVP development process.</p>
                
                <div class="row justify-content-center gy-4">
                    <div class="col-lg-4">
                        <div class="part">
                            <h4 class="head">Discovery and MVP Planning</h4>
                            <p>The first step is to finalize your project requirements for MVP development. It will help us and your team to plan the production.</p>
                            <ul>
                                <li>Business analysis for product</li>
                                <li>Feature road-mapping</li>
                                <li>MVP architecture design</li>
                                <li>Planning MVP integrations</li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="part">
                            <h4 class="head">PoC and Rapid Prototyping</h4>
                            <p>The first step is to finalize your project requirements for MVP development. It will help us and your team to plan the production.</p>
                            <ul>
                                <li>Business analysis for product</li>
                                <li>Feature road-mapping</li>
                                <li>MVP architecture design</li>
                                <li>Planning MVP integrations</li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="part">
                            <h4 class="head">Development Project Planning</h4>
                            <p>The first step is to finalize your project requirements for MVP development. It will help us and your team to plan the production.</p>
                            <ul>
                                <li>Business analysis for product</li>
                                <li>Feature road-mapping</li>
                                <li>MVP architecture design</li>
                                <li>Planning MVP integrations</li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="part">
                            <h4 class="head">MVP Development</h4>
                            <p>The first step is to finalize your project requirements for MVP development. It will help us and your team to plan the production.</p>
                            <ul>
                                <li>Business analysis for product</li>
                                <li>Feature road-mapping</li>
                                <li>MVP architecture design</li>
                                <li>Planning MVP integrations</li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="part">
                            <h4 class="head">MVP Testing and Launch</h4>
                            <p>The first step is to finalize your project requirements for MVP development. It will help us and your team to plan the production.</p>
                            <ul>
                                <li>Business analysis for product</li>
                                <li>Feature road-mapping</li>
                                <li>MVP architecture design</li>
                                <li>Planning MVP integrations</li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="part">
                            <h4 class="head">Support</h4>
                            <p>The first step is to finalize your project requirements for MVP development. It will help us and your team to plan the production.</p>
                            <ul>
                                <li>Business analysis for product</li>
                                <li>Feature road-mapping</li>
                                <li>MVP architecture design</li>
                                <li>Planning MVP integrations</li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="text-center">
                    <Link to="/contact" class="requirements">DISCUSS YOUR REQUIREMENTS</Link>
                </div>

            </div>
        </section>


        <section class="partner bg-white">
            <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-lg-5 pt-4">
                            <img src={appMobile} alt="mvp development" class="img-fluid" />
                        </div>
                        <div class="col-lg-7">
                            <h4 class="head">
                                Partner with our client-centered team for top-quality software solutions
                            </h4>
                            <p class="mt-n2 mb-25">Looking for top-notch software developers? Look no further! Our team of certified experts is dedicated to providing a client-centered approach and delivering the highest quality software using the software development life cycle (SDLC). Our team is comprised of experienced programmers who are known for their outstanding consulting services and for writing clean code.</p>
                            <p class="mt-n2 mb-25">Trust us, one of the most reliable software outsourcing companies, to make your mission-critical project a success by hiring one of our talented software developers.</p>
                            <Link to="/contact" class="hire-btn">Hire Us Now</Link>
                        </div>
                    </div>
                </div>
        </section>


        <section className="industry-mvp">
            <div className="heading">Industries</div>
            <Swiper
                modules={[Navigation, Autoplay]}
                className="mySwiper2"
                navigation={{
                    prevEl: '.swiper-button-prev2',
                    nextEl: '.swiper-button-next2',
                }}
                slidesPerView={4}
                spaceBetween={0}
                autoplay={{
                  delay: 3000,
                  disableOnInteraction: false, 
              }}
            >
                {industries.map((industry, index) => (
                    <SwiperSlide key={index}>
                        <div className="part">
                            <div className="image">
                                <img src={industry.img} alt={industry.name} className="img-fluid" />
                            </div>
                            <div className="content">
                                <p className="name">{industry.name}</p>
                            </div>
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>

            <div className="swiper-button-prev2">
                <i className="fa-solid fa-arrow-left"></i>
            </div>
            <div className="swiper-button-next2">
                <i className="fa-solid fa-arrow-right"></i>
            </div>
        </section>

        <section class="our-company-mvp">
            <div class="container">
                <h4 class="head">
                    <span>Why Stamens Software</span>
                    We are one of the best IT companies?
                </h4>
                <div class="row gy-4">
                    <div class="col-lg-3 col-md-4">
                        <div class="icons">
                            <div class="icon"><img src={customSoftwareDevelopment} alt="Custom Software Development" class="img-fluid" /></div>
                            <p>15+ Years in Industry</p>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-4">
                        <div class="icons">
                            <div class="icon"><img src={customSoftwareDevelopment} alt="Custom Software Development" class="img-fluid" /></div>
                            <p>Source Code Delivery to Clients</p>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-4">
                        <div class="icons">
                            <div class="icon"><img src={customSoftwareDevelopment} alt="Custom Software Development" class="img-fluid" /></div>
                            <p>Microsoft 365, Azure</p>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-4">
                        <div class="icons">
                            <div class="icon"><img src={customSoftwareDevelopment} alt="Custom Software Development" class="img-fluid" /></div>
                            <p>Less than 24 hrs Guaranteed Response</p>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-4">
                        <div class="icons">
                            <div class="icon"><img src={customSoftwareDevelopment} alt="Custom Software Development" class="img-fluid" /></div>
                            <p>Certified Developers</p>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-4">
                        <div class="icons">
                            <div class="icon"><img src={customSoftwareDevelopment} alt="Custom Software Development" class="img-fluid" /></div>
                            <p>Dedicated Project Manager</p>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-4">
                        <div class="icons">
                            <div class="icon"><img src={customSoftwareDevelopment} alt="Custom Software Development" class="img-fluid" /></div>
                            <p>Clients: USA, UK, Australia, etc.</p>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-4">
                        <div class="icons">
                            <div class="icon"><img src={customSoftwareDevelopment} alt="Custom Software Development" class="img-fluid" /></div>
                            <p>Handle Tight Deadlines</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
   
   <Footer />
   </>
  )
}

export default Mvp;
