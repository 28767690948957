import React, { useEffect, useState } from "react";
import Sidenavbar from "../components/sidenavbar";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
// import Testinomial from "../components/testinomial";
import { Link } from "react-router-dom";
import './home.css';
import Footer from "../components/footer";
import { Autoplay, Pagination } from "swiper/modules";
import Aos from 'aos';
import 'aos/dist/aos.css';
import { Helmet } from "react-helmet";
import CardComponent from "../components/cardComponent";


const designDevSoftwareIcon = `${process.env.PUBLIC_URL}/images/icons/software-development.svg`;
const callIcon = `${process.env.PUBLIC_URL}/images/call.svg`;
const aboutImage = `${process.env.PUBLIC_URL}/images/about.png`;
const newsImage1 = `${process.env.PUBLIC_URL}/images/news-1.png`;
const newsImage2 = `${process.env.PUBLIC_URL}/images/news-2.png`;
const newsImage3 = `${process.env.PUBLIC_URL}/images/news-3.png`;
const newsImage4 = `${process.env.PUBLIC_URL}/images/news-4.png`;
const careerImage = `${process.env.PUBLIC_URL}/images/career.png`;
const peopleGroupImage = `${process.env.PUBLIC_URL}/images/people-group.png`;
const circleArrowImage = `${process.env.PUBLIC_URL}/images/circle-arrow.png`;
const slackIcon = `${process.env.PUBLIC_URL}/images/slack-icon-2019.png`;
const dropboxIcon = `${process.env.PUBLIC_URL}/images/dropbox.png`;
const awsIcon = `${process.env.PUBLIC_URL}/images/aws.png`;
const githubIcon = `${process.env.PUBLIC_URL}/images/github.png`;
const gitlabIcon = `${process.env.PUBLIC_URL}/images/gitlab.png`;
const hubspotIcon = `${process.env.PUBLIC_URL}/images/hubspot.png`;
const experience = `${process.env.PUBLIC_URL}/images/icons/experiance.png`;
const timeDeliver = `${process.env.PUBLIC_URL}/images/icons/timeDeliver.png`;
const customerSupport = `${process.env.PUBLIC_URL}/images/icons/customer-support.png`;

const clients = [
  `${process.env.PUBLIC_URL}/images/clients/66deg-client.png`,
  `${process.env.PUBLIC_URL}/images/clients/cabi.png`,
  `${process.env.PUBLIC_URL}/images/clients/pro-it.png`,
  `${process.env.PUBLIC_URL}/images/clients/scadea-client.png`,
  `${process.env.PUBLIC_URL}/images/clients/techolution-client.png`,
  `${process.env.PUBLIC_URL}/images/clients/next-loop.png`
];

const sidebarItems = [
  {id: "banner", text: "Software Details", bgColor: 'blue' },
  {id: "design-dev", text: "Design and Develop", bgColor: 'white' },
  {id: "about-sec", text: "About", bgColor: 'white' },
  {id: "top-notch", text: "Top Notch", bgColor: 'blue'},
  {id: "career-sec", text: "Career", bgColor: 'white' },
  {id: "why-choose-us", text: "Why Choose", bgColor: 'blue'  },
  {id: "collaboration", text: "Collaboration", bgColor: 'white' },
  {id: "solutions", text: "Co Launcher", bgColor: 'blue' },
];

const DesignDev = [
  {
      image: designDevSoftwareIcon,
      title: 'Software Development',
      description: 'Transforming your IT vision into reality with innovative, tailored software solutions and unparalleled expertise.'
  },
  {
      image: designDevSoftwareIcon,
      title: 'Web Development',
      description: 'Crafting dynamic, user-friendly websites that drive engagement and elevate your online presence.'
  },
  {
      image: designDevSoftwareIcon,
      title: 'APP Development',
      description: 'Building cutting-edge apps that deliver seamless user experiences and drive business growth.'
  },
  {
    image: designDevSoftwareIcon,
    title: 'E-commerce Development',
    description: 'We Provide Unique and Best quality E-commerce Websites Development services at a Very Affordable Price.'
},
{
  image: designDevSoftwareIcon,
  title: 'Product Development',
  description: 'We help businesses transform their ideas into reality and drive success by delivering innovative and custom solutions for product and application.'
},
{
  image: designDevSoftwareIcon,
  title: 'Digital Marketing',
  description: 'Driving your business forward with strategic digital marketing that maximizes reach and converts leads into loyal customers.'
},
];

const WhyChoose =[
  {
    image: experience,
    title: 'Years of Experience',
    description: 'The players of the company are proficient at resolving client queries and offering plausible solutions to the client.The team always intends to provide great customer satisfaction with highly exceptional solutions.'
},
{
    image: timeDeliver,
    title: 'Timely Delivery',
    description: 'The team of professionals is dedicated to the single cause of delivering projects on time. With experience and knowledge, you can only expect quality and performance rich solutions from our end.'
},
{
    image: customerSupport,
    title: '24X7 Customer Support',
    description: 'Our specialized team offers its customers assistance 24 X 7. The team is always available to assist clients with their queries and offer them support on their requests.'
}
]

function  Home() {
  const [activeId, setActiveId] = useState("banner");

  useEffect(() => {
    const maxWidth = 991;
    
    Aos.init({
      duration: 1000,
      easing: 'linear',
      mirror: false,
      once: true,
      disable: () => {
        return window.innerWidth < maxWidth;
      },
    });

    const interval = setInterval(() => {
      Aos.refresh();
    }, 1000);

    let scrollTimeout;

    const handleScroll = () => {
      clearTimeout(scrollTimeout);

      scrollTimeout = setTimeout(() => {
        const sections = sidebarItems.map(item => document.getElementById(item.id));
        const scrollPosition = window.scrollY + window.innerHeight / 2; // Middle of the viewport

        sections.forEach(section => {
          if (section) {
            const sectionTop = section.offsetTop;
            const sectionHeight = section.clientHeight;

            // Check if the current scroll position is within the section
            if (scrollPosition >= sectionTop && scrollPosition < sectionTop + sectionHeight) {
              setActiveId(section.id);
            }
          }
        });
      }, 100); // Adjust the debounce delay as needed
    };

    window.addEventListener("scroll", handleScroll);
    
    // Cleanup on component unmount
    return () => {
      clearInterval(interval);
      window.removeEventListener("scroll", handleScroll);
    };

  }, []);

  console.log(activeId);
  
  return (
    <>
    <Helmet>
    <title>Web Application and Software Development Company | Stamens Software</title>
    </Helmet>
    <Sidenavbar items={sidebarItems} activeId={activeId}/>
    

    <main  data-bs-spy="scroll" data-bs-target="#list-example" data-bs-smooth-scroll="true" className="scrollspy-example" tabindex="0">
        
    <section id="banner">
            <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel">
                <div className="carousel-inner">
                    <div className="carousel-item active">
                        <div className="banner">
                            <div className="container">
                                <div className="row justify-content-start align-items-center">
                                    <div className="col-lg-6" data-aos="fade-right" data-aos-easing="linear" data-aos-duration="500">
                                        <h1 className="banner-head">
                                            <span>we are stamens</span>
                                            custom product &amp; software development
                                        </h1>
                                        <p className="tagline">we excel in transforming innovative ideas into functional solutions.Our expert team uses the latest technologies to create robust, scalable applications that deliver exceptional performance. Partner with us to turn your vision into reality.</p>
                                        <div className="btn-group">
                                            <Link to="/contact">consult our experts</Link>
                                            <Link type="button" data-bs-toggle="modal" data-bs-target="#exampleModalcall">schedule a call</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="carousel-item">
                        <div className="banner banner2">
                            <div className="container">
                                <div className="row justify-content-start align-items-center">
                                    <div className="col-lg-6" >
                                        <h1 className="banner-head">
                                            <span>we are stamens</span>
                                            Web Application Development
                                        </h1>
                                        <p className="tagline">A web application or web app is a software application that can be accessible via a web browser or a web-enabled device, such as a smartphone or tablet. It operates on a web server and anyone with an internet connection can use it.</p>
                                        <div className="btn-group">
                                            <Link to="/contact">consult our experts</Link>
                                            <Link data-bs-toggle="modal" data-bs-target="#exampleModalcall">schedule a call</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="carousel-item">
                        <div className="banner banner3">
                            <div className="container">
                                <div className="row justify-content-start align-items-center">
                                    <div className="col-lg-6" >
                                        <h1 className="banner-head">
                                            <span>we are stamens</span>
                                            Mobile App Development
                                        </h1>
                                        <p className="tagline">Mobile application development is the process of creating software applications that run on a mobile device, and a typical mobile application utilizes a network connection to work with remote computing resources.</p>
                                        <div className="btn-group">
                                            <Link to="/contact">consult our experts</Link>
                                            <Link data-bs-toggle="modal" data-bs-target="#exampleModalcall">schedule a call</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="carousel-item">
                        <div className="banner banner4">
                            <div className="container">
                                <div className="row justify-content-start align-items-center">
                                    <div className="col-lg-6" >
                                        <h1 className="banner-head">
                                            <span>we are stamens</span>
                                            E-commerce Development
                                        </h1>
                                        <p className="tagline">An emerging eCommerce website development company based in India develops the most reliable customer-centric e-commerce websites from the front end and simply manageable ones from the back end, which generates sales and simultaneously increases revenue.</p>
                                        <div className="btn-group">
                                            <Link to="/contact">consult our experts</Link>
                                            <Link data-bs-toggle="modal" data-bs-target="#exampleModalcall">schedule a call</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="carousel-item">
                        <div className="banner banner5">
                            <div className="container">
                                <div className="row justify-content-start align-items-center">
                                    <div className="col-lg-6" >
                                        <h1 className="banner-head">
                                            {/* <span>we are stamens</span> */}
                                            Hire Dedicated Developers
                                        </h1>
                                        <p className="tagline">Looking to hire dedicated developers from India? We have a team of expert web and mobile app developers for hire on a full-time, part-time, or hourly basis. Maximize Your ROI and Streamline Your Software Development with Our Dedicated Teams - Partner with Us.</p>
                                        <div className="btn-group">
                                            <Link to="/contact">consult our experts</Link>
                                            <Link data-bs-toggle="modal" data-bs-target="#exampleModalcall">schedule a call</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="carousel-indicators">
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="3" aria-label="Slide 4"></button>
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="4" aria-label="Slide 5"></button>
                </div>
            </div>
        </section>

        <section className="design-dev" id="design-dev">
          <div className="container">
            <h2 className="head text-center" data-aos="zoom-in" data-aos-easing="linear" data-aos-duration="800">
              <span>our design</span>
              design and develop a digital product from the ground up.
            </h2>
            <div className="row gy-4 justify-content-center justify-content-lg-between mt-5">
            {DesignDev.map((card, index) => (
                <CardComponent key={index} cardProps={card} dataAos={"zoom-in"} dataAosEasing={'linear'} dataAosDuration={'800'}/>
            ))}
            </div>
            <div className="cunsult-strip">
              <div className="cta-content">
                <div className="cta-wrapper" data-aos="fade-right" data-aos-easing="linear" data-aos-duration="800">
                  <div className="cta-icon">
                    <Link href="tel:+1235859459">
                      <img src={callIcon} alt="call" className="img-fluid" />
                    </Link>
                  </div>
                  <div className="media-body">
                    <span className="header-info_label text-white">Call For More Info</span>
                    <p className="header-info_link"><a href="tel:+911203500847">+91 120 3500 847</a></p>
                  </div>
                </div>
                <div className="title-area mb-0" data-aos="zoom-in" data-aos-easing="linear" data-aos-duration="800">
                  <h4 className="sec-title text-white">Let’s Request a Schedule For Free Consultation</h4>
                </div>
                <div className="cta-group" data-aos="fade-left" data-aos-easing="linear" data-aos-duration="1000">
                  <Link to='/contact' className="custom-btn">Contact Us</Link>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="about-sec" id="about-sec">
            <div className="container">
                <div className="about-area">
                    <div className="row">
                        <div className="col-xl-6" data-aos="fade-right" data-aos-easing="linear" data-aos-duration="1000">
                            <h3 className="head">
                                <span>About Stamens Software</span>
                                Innovate business solution for startup companies
                            </h3>
                            <p className="mt-n2 mb-25">At Stamens Software, we specialize in innovative business solutions tailored for startups. Our mission is to help you identify growth opportunities and expand your market presence through cutting-edge strategies and technology.</p>
                            <ul className="check-list">
                                <li><i className="far fa-check-circle"></i> We provide innovative software solutions tailored for startup success.</li>
                                <li><i className="far fa-check-circle"></i> Our expertise helps identify and seize new market opportunities.</li>
                                <li><i className="far fa-check-circle"></i> We focus on optimizing processes to boost operational efficiency and reduce waste.</li>
                                <li><i className="far fa-check-circle"></i> Advanced analytics enable informed decision-making with data-driven insights.</li>
                            </ul>
                            <Link to="/about" className="custom-btn">Know More</Link>
                        </div>
                        <div className="col-xl-6" data-aos="fade-left" data-aos-easing="linear" data-aos-duration="1000">
                            <div className="img-box">
                                <div className="imgarea">
                                    <img src={aboutImage} alt="About" className="img-fluid" />
                                </div>
                                <div className="experience">
                                    <h2 className="experience-year">
                                            <span className="counter-number">15+</span>
                                    </h2>
                                    <p className="experience-text">Years of experience in startup building</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
             
        </section>

        <section className="top-notch" id="top-notch">
            <div className="container">
                <div className="row justify-content-start  content-part">
                    <div className="col-xl-6" data-aos="fade-right" data-aos-easing="linear" data-aos-duration="1000">
                        <h2 className="head">
                            <span>Design Develop Optimize</span>
                            top-notch design , great code
                        </h2>
                        <ul className="list">
                            <li><Link href="#">How we transform complex challenges into elegant solutions to deliver unparalleled results.</Link></li>
                            <li><Link href="#">Experience exceptional design and flawless code. We specialize in turning intricate challenges into elegant solutions.</Link></li>
                            <li><Link href="#">Our expertise lies in crafting intuitive designs and writing impeccable code, ensuring every project exceeds expectations.</Link></li>
                        </ul>
                        <Link to="/service" className="custom-btn">Our Services</Link>
                    </div>
                </div>
                <div className="news-images">
                    <img src={newsImage1} alt="news 1" height="359" width="359" className="img-fluid" data-aos="zoom-in" data-aos-easing="linear" data-aos-duration="1000" />
                    <img src={newsImage2} alt="news 2" height="359" width="359" className="img-fluid" data-aos="zoom-in" data-aos-easing="linear" data-aos-duration="1000" />
                    <img src={newsImage3} alt="news 3" height="359" width="359" className="img-fluid" data-aos="zoom-in" data-aos-easing="linear" data-aos-duration="1000" />
                    <img src={newsImage4} alt="news 4" height="359" width="359" className="img-fluid" data-aos="zoom-in" data-aos-easing="linear" data-aos-duration="1000" />
                </div>
            </div>
        </section>

        <section className="career" id="career-sec">
          <div className="container">
            <h3 className="head text-center" data-aos="zoom-in" data-aos-easing="linear" data-aos-duration="1000">
              <span>Career Strategy</span>
              Enhance Your Career for Superior Outcomes
            </h3>

            <div className="img-text-group">
              <img src={careerImage} alt="Career" height="628" width="944" className="img-fluid main-image" />
              <div className="content" data-aos="fade-left" data-aos-delay="600" data-aos-easing="linear">
                        <div className="part">
                            <img src={peopleGroupImage} alt="news 1" height="45" width="178" className="img-fluid mb-3" />
                            <p>20+ Strong Team of IT Experts, Passionate About Your Growth</p>
                            <h4 className="fw-bold">Consult Our Experts</h4>
                        </div>
                        <div className="part">
                            <img src={circleArrowImage} alt="news 1" height="95" width="95" className="img-fluid" />
                            <div>
                                <h4 className="fw-bold">Innovation and Excellence</h4>
                                <p>20+ Skilled Experts Drives Innovation and Excellence</p>
                            </div>
                        </div>
                    </div>
            
            </div>
          </div>
        </section>
        
        <section className="why-choose-us" id="why-choose-us">
            <div className="container">
                <h4 className="head text-center" data-aos="zoom-in" data-aos-easing="linear" data-aos-duration="1000">
                    <span>Why Choose Us</span>
                    We believe in quality work with an established authority or expertise.
                </h4>
                <div className="row justify-content-center">
                {WhyChoose.map((card, index) => (
                <CardComponent key={index} cardProps={card} parentChildClass={'part'}  childClassA={null} data-aos="fade-right" data-aos-easing="linear" data-aos-duration="1000"/>
            ))}
                </div>
            </div>
        </section>

        <section className="collaboration" id="collaboration">
      <div className="container">
        <div className="d-flex align-items-end justify-content-between mb-4" data-aos="fade-right" data-aos-easing="linear" data-aos-duration="1000">
          <div>
            <h4 className="head">
              <span>Collaboration</span>
              Effortless Collaboration
            </h4>
            <p className="tagline">Streamlined solutions for seamless teamwork and productivity.</p>
          </div>
          <Link to="/contact" className="custom-btn">Connect with Us</Link>
        </div>
        <div className="row justify-content-center" data-aos="fade-left" data-aos-easing="linear" data-aos-duration="1000">
          <div className="col-xl-2 col-lg-5">
            <div className="part">
              <img src={slackIcon} alt="Slack" height="100" width="100" className="img-fluid" />
              <div>
                <h4 className="fs-3 mt-3">Slack</h4>
                <p>It allows users to communicate.</p>
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-lg-7">
            <div className="part d-flex align-items-center gap-3">
              <img src={dropboxIcon} alt="Dropbox" height="100" width="100" className="img-fluid" />
              <div>
                <h4>Dropbox</h4>
                <p>A cloud storage service that lets you store files online.</p>
              </div>
            </div>
            <div className="part d-flex align-items-center gap-3">
              <img src={awsIcon} alt="Amazon" height="100" width="100" className="img-fluid" />
              <div>
                <h4>Amazon</h4>
                <p>Widely adopted cloud platform.</p>
              </div>
            </div>
          </div>
          <div className="col-xl-2 col-lg-5">
            <div className="part">
              <img src={githubIcon} alt="GitHub" height="100" width="100" className="img-fluid" />
              <div>
                <h4 className="fs-3 mt-3">GitHub</h4>
                <p>For version control and collaboration</p>
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-lg-7">
            <div className="part d-flex align-items-center gap-3">
              <img src={gitlabIcon} alt="GitLab" height="100" width="100" className="img-fluid" />
              <div>
                <h4>GitLab</h4>
                <p>Praised for its all-in-one DevOps capabilities</p>
              </div>
            </div>
            <div className="part d-flex align-items-center gap-3">
              <img src={hubspotIcon} alt="HubSpot" height="100" width="100" className="img-fluid" />
              <div>
                <h4>HubSpot</h4>
                <p>Customer service platform</p>
              </div>
            </div>
          </div>
        </div>

        <div className="row justify-content-center mt-5" data-aos="zoom-in" data-aos-easing="linear" data-aos-duration="1000">
          <div className="col-lg-12">
            <Swiper
            modules={[Autoplay, Pagination]}
              className="mySwiper3"
              spaceBetween={30}
              slidesPerView={5}
              autoplay={{
                delay: 1000,
                disableOnInteraction: true,
              }}
            >
              {clients.map((client, index) => (
                <SwiperSlide key={index}>
                  <img src={client} alt={`Client ${index + 1}`} className="img-fluid" height="103" width="237" />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
        
      </div>
    </section>

        <section className="solutions" id="solutions">
            <div className="container">
                <h4 className="head text-center text-white col-md-12 mx-auto text-center" data-aos="zoom-in" data-aos-easing="linear" data-aos-duration="800">
                    unlock timely solutions with cutting-Edge software
                </h4>
                <p className="tagline col-md-9 mx-auto text-center fs-4 mb-5 d-block" data-aos="zoom-in" data-aos-easing="linear" data-aos-duration="1000">Transform your business with the latest advancements in software technology.</p>

                <Link to="/contact" className="custom-btn" data-aos="zoom-in" data-aos-easing="linear" data-aos-duration="1200">connect with us</Link>
            </div>
        </section>

<Footer />
    </main>


    </>
  );
}

export default Home;