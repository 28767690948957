import React from 'react';
const stamenslogo = `${process.env.PUBLIC_URL}/images/logo.svg`;

function Scheduleacall() {
    
    
  return (
    <div class="modal fade" id="exampleModalcall" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-md modal-dialog-centered">
        <div class="modal-content popup-modal-call-details">
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">X</button>
            <div class="modal-body">
              <div class="text-center d-block w-100 py-4">
                <a class="pb-2 ps-2 d-block" href="#"><img src={stamenslogo} alt="main logo" height="53" width="124" class="img-fluid" /></a>
              </div>
              <div class="call-details">
                  <div class="part">
                      <p class="heading"><i class="fa fa-phone" aria-hidden="true"></i> Telephone</p>
                      <a href="tel:+911203500847" class="d-block">+91-120 3500 847, 48</a>
                  </div>
                  <div class="part">
                      <p class="heading"><i class="fa fa-envelope" aria-hidden="true"></i> Write to us</p>
                      <a href="mailto:contact@stamenssoftware.com">contact@stamenssoftware.com</a>
                  </div>
              </div>
            </div>
        </div>
    </div>
</div>

  )

}

export default Scheduleacall;