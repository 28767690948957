import React from 'react';
import './digitalmarketing.css';
import Footer from '../components/footer';
import { Helmet } from 'react-helmet';
import Banner from '../components/banner';
import Process from '../components/process';
import ServiceComponent from '../components/servicecomponent';
import LetsCode from '../components/letscode';

const apiIntegrationsImg = `${process.env.PUBLIC_URL}/images/icons/api-integrations.png`;

const dmTool1 = `${process.env.PUBLIC_URL}/images/tools/dm-tool-1.png`;
const dmTool2 = `${process.env.PUBLIC_URL}/images/tools/dm-tool-2.png`;
const dmTool3 = `${process.env.PUBLIC_URL}/images/tools/dm-tool-3.png`;
const dmTool4 = `${process.env.PUBLIC_URL}/images/tools/dm-tool-4.png`;
const dmTool5 = `${process.env.PUBLIC_URL}/images/tools/dm-tool-5.png`;
const dmTool6 = `${process.env.PUBLIC_URL}/images/tools/dm-tool-6.png`;
const dmTool7 = `${process.env.PUBLIC_URL}/images/tools/dm-tool-7.png`;
const dmTool8 = `${process.env.PUBLIC_URL}/images/tools/dm-tool-8.png`;
const dmTool9 = `${process.env.PUBLIC_URL}/images/tools/dm-tool-9.png`;
const dmTool10 = `${process.env.PUBLIC_URL}/images/tools/dm-tool-10.png`;
const dmTool11 = `${process.env.PUBLIC_URL}/images/tools/dm-tool-11.png`;
const dmTool12 = `${process.env.PUBLIC_URL}/images/tools/dm-tool-12.png`;

const elegantFemale = `${process.env.PUBLIC_URL}/images/elegant-female.png`;
const customSoftwareDevelopment = `${process.env.PUBLIC_URL}/images/icons/custom-software-development.png`;

const bannerDetails =
  {
      title: 'Our Services',
      subTitle: 'Digital Marketing',
      description: 'Effective digital marketing drives growth and visibility. Our tailored strategies ensure your brand reaches the right audience at the right time. From SEO to social media management, we focus on delivering measurable results that elevate your online presence and boost engagement.'
  }

  const processCard = [
    {
        spanContent: 'Works',
        heading: 'We are Offering',
        subheading: " Our suite of digital marketing solutions is tailored to your business needs, from comprehensive SEO strategies to seamless API integrations, helping you stay ahead in the digital landscape.",
        processItems: [
            {
                num: '01',
                image: apiIntegrationsImg,
                contentheading: 'SEO',
                description : 'We conduct comprehensive audits, perform keyword research, optimize on-page elements, and create content strategies designed to attract organic traffic.',
                link: '/search-engine-optimization-services-india'
            },
            {
                num: '02',
                image: apiIntegrationsImg,
                contentheading: 'Social Media',
                description : 'Our team helps you craft compelling social media campaigns that build brand awareness, engage with your audience, and drive conversions.',
                link : '/social-media-marketing-india'
            },
            {
              num: '03',
              image: apiIntegrationsImg,
              contentheading: 'Google Ads',
              description : 'From keyword research to ad copywriting and bid management, we optimize your Google Ads campaigns for maximum ROI.',
              link : '/google-ads-services-india'
            }
        ]
    },
    {

    }
]

const Services = [
{
  image : apiIntegrationsImg,
  heading : 'SEO Services'
},
{
  image : apiIntegrationsImg,
  heading : 'PPC Management'
},
{
  image : apiIntegrationsImg,
  heading : 'Web Design & Development'
},
{
  image : apiIntegrationsImg,
  heading : 'Social Media Marketing'
},
{
  image : apiIntegrationsImg,
  heading : 'Content Marketing'
},
{
  image : apiIntegrationsImg,
  heading : 'Paid Social Marketing'
}
]

function Digitalmarketing() {
  return (
    <>

    <Helmet>
    <title>Digital Marketing | Stamens Software</title>
    </Helmet>

<Banner details={bannerDetails} descriptionClass={'text-center fs-5'}/>

{processCard.map((item, index) => (
        <Process 
          key={index}
          spanContent={item.spanContent}
          heading={item.heading}
          subheading={item.subheading}
          processItems={item.processItems}
          parentClass={"digital-process"}
        />
      ))}

<section class="digital-service bg-white">
            <div class="container">
                <h3 class="head text-center">
                    Boost Sales With Our Digital Marketing Service
                </h3>
                <p class="text-center fs-5 pb-4">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                <div class="row justify-content-center gy-4">
                {Services.map((card, index) => (
                  <ServiceComponent key={index} serviceprops={card} h4={'h4'} />
                ))}
                </div>
            </div>
        </section>

  <LetsCode />

  <section class="our-fetaures-id">
            <div class="container">
                <h3 class="head text-center"> Transform Your Brand's Online Presence With Digital Marketing Service </h3>
                <p class="text-center fs-5 pb-4">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>

                <div class="row justify-content-center gy-4">
                    <div class="col-lg-3 col-md-6">
                        <div class="part">
                            <span class="counter">65%</span>
                            <h3 class="small-head">Increased ROI</h3>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6">
                        <div class="part">
                            <span class="counter">150%</span>
                            <h3 class="small-head">conversion rates Growth </h3>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6">
                        <div class="part">
                            <span class="counter">410%</span>
                            <h3 class="small-head">Traffic Growth</h3>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6">
                        <div class="part">
                            <span class="counter">76%</span>
                            <h3 class="small-head">Increased Social Media Engagement</h3>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="technologie-dm">
            <div class="container">
                <h3 class="head">
                    <span>Our Technologies</span>
                    All Technologies Software in stamens
                </h3>
                <div class="row gy-4">
                    <div class="col-lg-3 col-md-4">
                        <div class="icons">
                            <img src={dmTool1} alt="tool icon 1" class="img-fluid" width="200" height="100" />
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-4">
                        <div class="icons">
                            <img src={dmTool2} alt="tool icon 2" class="img-fluid" width="200" height="100" />
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-4">
                        <div class="icons">
                            <img src={dmTool3} alt="tool icon 3" class="img-fluid" width="200" height="100" />
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-4">
                        <div class="icons">
                            <img src={dmTool4} alt="tool icon 4" class="img-fluid" width="200" height="100" />
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-4">
                        <div class="icons">
                            <img src={dmTool5} alt="tool icon 5" class="img-fluid" width="200" height="100" />
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-4">
                        <div class="icons">
                            <img src={dmTool6} alt="tool icon 6" class="img-fluid" width="200" height="100" />
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-4">
                        <div class="icons">
                            <img src={dmTool7} alt="tool icon 7" class="img-fluid" width="200" height="100" />
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-4">
                        <div class="icons">
                            <img src={dmTool8} alt="tool icon 8" class="img-fluid" width="200" height="100" />
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-4">
                        <div class="icons">
                            <img src={dmTool9} alt="tool icon 9" class="img-fluid" width="200" height="100" />
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-4">
                        <div class="icons">
                            <img src={dmTool10} alt="tool icon 10" class="img-fluid" width="200" height="100" />
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-4">
                        <div class="icons">
                            <img src={dmTool11} alt="tool icon 11" class="img-fluid" width="200" height="100" />
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-4">
                        <div class="icons">
                            <img src={dmTool12} alt="tool icon 12" class="img-fluid" width="200" height="100" />
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="partner bg-white">
            <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-lg-5 pt-4">
                            <img src={elegantFemale} alt="vision" class="img-fluid rounded" style={{height: '100%', objectFit: 'cover'}} />
                        </div>
                        <div class="col-lg-7">
                            <h4 class="head">
                                Partner with our client-centered team for top-quality software solutions
                            </h4>
                            <p class="mt-n2 mb-25">Looking for top-notch software developers? Look no further! Our team of certified experts is dedicated to providing a client-centered approach and delivering the highest quality software using the software development life cycle (SDLC). Our team is comprised of experienced programmers who are known for their outstanding consulting services and for writing clean code.</p>
                            <p class="mt-n2 mb-25">Trust us, one of the most reliable software outsourcing companies, to make your mission-critical project a success by hiring one of our talented software developers.</p>
                            <a href="#" class="hire-btn">Hire Us Now</a>
                        </div>
                    </div>
                </div>
        </section>


        <section class="our-company">
            <div class="container">
                <h4 class="head">
                    <span>Why Stamens Software</span>
                    We are one of the best IT companies?
                </h4>
                <div class="row gy-4">
                    <div class="col-lg-3 col-md-4">
                        <div class="icons">
                            <div class="icon"><img src={customSoftwareDevelopment} alt="Custom Software Development" class="img-fluid" /></div>
                            <p>15+ Years in Industry</p>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-4">
                        <div class="icons">
                            <div class="icon"><img src={customSoftwareDevelopment} alt="Custom Software Development" class="img-fluid" /></div>
                            <p>Source Code Delivery to Clients</p>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-4">
                        <div class="icons">
                            <div class="icon"><img src={customSoftwareDevelopment} alt="Custom Software Development" class="img-fluid" /></div>
                            <p>Microsoft 365, Azure</p>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-4">
                        <div class="icons">
                            <div class="icon"><img src={customSoftwareDevelopment} alt="Custom Software Development" class="img-fluid" /></div>
                            <p>Less than 24 hrs Guaranteed Response</p>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-4">
                        <div class="icons">
                            <div class="icon"><img src={customSoftwareDevelopment} alt="Custom Software Development" class="img-fluid" /></div>
                            <p>Certified Developers</p>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-4">
                        <div class="icons">
                            <div class="icon"><img src={customSoftwareDevelopment} alt="Custom Software Development" class="img-fluid" /></div>
                            <p>Dedicated Project Manager</p>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-4">
                        <div class="icons">
                            <div class="icon"><img src={customSoftwareDevelopment} alt="Custom Software Development" class="img-fluid" /></div>
                            <p>Clients: USA, UK, Australia, etc.</p>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-4">
                        <div class="icons">
                            <div class="icon"><img src={customSoftwareDevelopment} alt="Custom Software Development" class="img-fluid" /></div>
                            <p>Handle Tight Deadlines</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>     

    <Footer />
    </>
  )
}

export default Digitalmarketing;
