import React from "react";
import Home from "./pages/home";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Header from "./components/header";
// import Footer from "./components/footer";
import About from "./pages/about";
import Services from "./pages/services";
import Contact from "./pages/contact";
import Career from "./pages/career";
import CallFormButton from "./components/callformbutton";
import Callform from "./components/callform";
import LegalNotice from "./pages/legalNotice";
import PrivacyPolicy from "./pages/privacyPolicy";
import Termsandconditions from "./pages/termsandconditions";
import ScrollToTop from "./components/scrolltotop";
import Gallery from "./pages/gallery";
import Scheduleacall from "./components/scheduleacall";
import Customsoftwaredevelopment from "./pages/customsoftwaredevelopment";
import Error from "./pages/error";
import WebDevelopmentService from "./pages/web-development-service";
import MobileAppDevelopmentService from "./pages/mobile-app-development-service";
import ECommerceDevelopment from "./pages/e-commerce-development";
import HireDedicatedTeam from "./pages/hireServices";
import ImplementationAndDeployment from "./pages/implementationanddeployment";
import ApiIntegration from "./pages/apiIntegration";
import UiUx from "./pages/uiux";
import Mvp from "./pages/mvp-development";
import CloudServices from "./pages/cloudservices";
// import Blog from "./pages/blog";
// import BlogPost from "./components/Blogpost";
import TechnicalDSupport from "./pages/technicalsupport";
import Digitalmarketing from "./pages/digitalmarketing";
import SeoService from "./pages/seoservice";
import SocialMedia from "./pages/socialmedia";
import GooleAds from "./pages/googleads";

function App() {
  return (
    <Router>
      <div className="">
        <Header />
        <CallFormButton />
        <Callform />
        <Scheduleacall />
        <ScrollToTop />
        <div>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/service" element={<Services />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/career" element={<Career />} />
            <Route path="/gallery" element={<Gallery />} />
            {/* <Route path="/blog" element={<Blog />} /> */}
            {/* <Route path="/blog/:id" element={<BlogPost />} /> */}
            <Route path="/legalnotice" element={<LegalNotice />} />
            <Route path="/privacypolicy" element={<PrivacyPolicy />} />
            <Route path="/termsandconditions" element={<Termsandconditions />} />
            <Route path="/custom-software-development-company" element={<Customsoftwaredevelopment />} />
            <Route path="/api-integration" element={<ApiIntegration />} />
            <Route path="/best-ui-ux-design-services" element={<UiUx />} />
            <Route path="/mvp-development" element={<Mvp />} />
            <Route path="/cloud-services" element={<CloudServices />} />
            <Route path="/web-development-services" element={<WebDevelopmentService />} />
            <Route path="/mobile-app-development-services" element={<MobileAppDevelopmentService />} />
            <Route path="/e-commerce-development-services" element={<ECommerceDevelopment />} />
            <Route path="/hire-dedicated-team" element={<HireDedicatedTeam />} />
            <Route path="/implementation-and-deployment" element={<ImplementationAndDeployment />} />
            <Route path="/technical-it-support" element={<TechnicalDSupport />} />
            <Route path="/digital-marketing-services" element={<Digitalmarketing />} />
            <Route path="/search-engine-optimization-services-india" element={<SeoService />} />
            <Route path="/social-media-marketing-india" element={<SocialMedia />} />
            <Route path="/google-ads-services-india" element={<GooleAds />} />
            <Route path="*" element={<Error />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;
