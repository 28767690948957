import React, { useState } from "react";
import { Link } from "react-router-dom";
// import Callform from "./callform";
const stamenslogo = `${process.env.PUBLIC_URL}/images/logo.svg`;


function  Header() {

  const [showMenuItems, setShowMenuItems] = useState(false);

  return (
    <header>
        <nav className="navbar navbar-expand-lg">
            <div className="container">
              <Link className="navbar-brand" to="/"><img src={stamenslogo} alt="main logo" height="53" width="124" className="img-fluid" /></Link>
              <button className="navbar-toggler" onClick={() => setShowMenuItems((prev) => !prev)} type="button"  aria-controls="navbarSupportedContent" aria-expanded={showMenuItems} aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
              </button>
              <div className={`collapse navbar-collapse ${showMenuItems ? 'show' : ''}`} id="navbarSupportedContent" >
                <ul className="navbar-nav ms-auto mb-2 mb-lg-0" >
                    <li className="nav-item" >
                      <Link className="nav-link" to="/" onClick={() => setShowMenuItems(false)}>Home</Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/about"  onClick={() => setShowMenuItems(false)}>About</Link>
                    </li>
                    <li className="nav-item" >
                    <Link className="nav-link" to="/service"  onClick={() => setShowMenuItems(false)}>Services</Link>
                </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/gallery"  onClick={() => setShowMenuItems(false)}>Gallery</Link>
                    </li>
                    {/* <li className="nav-item">
                      <Link className="nav-link" to="/blog"  onClick={() => setShowMenuItems(false)}>Blog</Link>
                    </li> */}
                    <li className="nav-item">
                      <Link className="nav-link" to="/career"  onClick={() => setShowMenuItems(false)}>Career</Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/contact"  onClick={() => setShowMenuItems(false)}>Contact</Link>
                    </li>
                    <Link className="nav-link call-btn" data-bs-toggle="modal" data-bs-target="#exampleModal"  onClick={() => setShowMenuItems(false)}>
                  Work with Us
                </Link>
                </ul>
              </div>
            </div>
        </nav>
    </header>
  );
}

export default Header;