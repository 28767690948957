import React, { useEffect, useRef, useState } from "react";
import { Link } from 'react-router-dom';
import '../pages/career.css';

function CareerForm(){

    const [experienceLevel, setExperienceLevel] = useState('');

    const [formData, setFormData] = useState({
        Name: '',
        Email: '',
        Phone: '',
        Post:'',
        Message: '',
        Current_CTC: '',
        Expected_CTC:''

      });

      const [errors, setErrors] = useState({
        Name: '',
        Email: '',
        Phone: '',
        Post: '',
        File:'',
        Message: '',
        cv: '',
        captcha: '',
      });

      const URL = `${process.env.REACT_APP_CONT_URL}`+'/api/JobSeeker'

      const [captcha, setCaptcha] = useState('');
      const [captchaInput, setCaptchaInput] = useState('');
      const canvasRef = useRef(null);

      const generateCaptcha = () => {
        const characters = 'ABCDEFGHJKMNPQRSTUVWXYZabcdefghjkmnpqrstuvwxyz123456789';
        let result = '';
        for (let i = 0; i < 6; i++) {
          result += characters.charAt(Math.floor(Math.random() * characters.length));
        }
        setCaptcha(result);
      };

      const drawCaptcha = () => {
        const canvas = canvasRef.current;
        if (canvas) {
          const ctx = canvas.getContext('2d');
          ctx.clearRect(0, 0, canvas.width, canvas.height); // Clear previous content
          ctx.fillStyle = '#f0f0f0'; // Background color
          ctx.fillRect(0, 0, canvas.width, canvas.height);
    
          ctx.font = '30px Arial';
          ctx.fillStyle = '#000000'; // Text color
    
          // Draw the CAPTCHA text with random positioning
          for (let i = 0; i < captcha.length; i++) {
            ctx.save();
            ctx.translate(30 * i + 10, Math.random() * 10 + 20); // Random x position and slight y variation
            ctx.rotate(Math.random() * 0.2 - 0.1); // Slight rotation
            ctx.fillText(captcha[i], 0, 0);
            ctx.restore();
          }
    
          // Add random lines
          for (let i = 0; i < 5; i++) {
            ctx.strokeStyle = '#cccccc';
            ctx.beginPath();
            ctx.moveTo(Math.random() * canvas.width, Math.random() * canvas.height);
            ctx.lineTo(Math.random() * canvas.width, Math.random() * canvas.height);
            ctx.stroke();
          }
        }
      };
    
      useEffect(() => {
        generateCaptcha();
      }, []);

      useEffect(() => {
        drawCaptcha();
      }, [captcha]);

    const [fileInput, setFileInput] = useState({});

    const formRef = useRef();
    const fileInputRef = useRef(null);

    const validateName = (name) => {
        const nameRegex = /^[A-Za-z]+$/;
        return nameRegex.test(name);
      };
  
      const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
      };
    
      const validatePhone = (phone) => {
        // Regular expression for validating phone numbers with optional "+" and optional space
        const phoneRegex = /^\+?[\d\s\-()]{7,15}$/;
        return phoneRegex.test(phone);
      };
  
      const validateFileInput = (fileInput) =>{
      
        
     if(fileInput?.type === "application/pdf"){
          return true;
        }
          else if(fileInput?.type === "application/doc"){
          return true;
        }else if(fileInput?.type === "application/docx"){
          return true;
        }
        else {
          return false;
        }
        
      }


    const handleChange = (e) =>{
        const { name, value, type, checked, files } = e.target;
        if(checked){
        setExperienceLevel(e.target.value);
        }
        else if (type === 'file') {
            setFileInput(files[0]);
        } else if (name === 'captcha') {
            setCaptchaInput(value);
        } else if (name === 'Current_CTC' || name === 'Expected_CTC') {
            setFormData(prevState => ({
                ...prevState,
                [name]: value
            }));
        }else{
        setFormData(prevState =>({
            ...prevState,
            [name] : value
        }));
    }
    }
    
    const handleSubmit = (e) =>{
        e.preventDefault();
        let valid = true;
        let newErrors = {};
        
        if(!formData.Name){
            valid = false;
            newErrors.Name = 'Name is required';
        }else if(!validateName(formData.Name)){
            valid = false;
            newErrors.Name = 'Only characters are allowed in the name!';
        }

        if(!formData.Email){
            valid = false;
            newErrors.Email = 'Email is required';
        }else if(!validateEmail(formData.Email)){
            valid = false;
            newErrors.Email = 'Not a valid mail';
        }
     
        if(!formData.Phone){
            valid = false;
            newErrors.Phone = 'Phone Number is Required';
        }else if(!validatePhone(formData.Phone)){
            valid = false;
            newErrors.Phone = 'Not a number';
        }
       
        
        const fileextension = validateFileInput(fileInput);
        if (fileextension === false) {
        
          valid = false;
          newErrors.cv = 'upload file having extension pdf, doc, docx';
        }
        
        if(!captchaInput){
          valid = false;
          newErrors.captcha = 'Enter Captcha';
        }else if (captchaInput !== captcha) {
          valid = false;
          newErrors.captcha = 'CAPTCHA does not match';
          generateCaptcha();
        }

        const currentCtcLakhs = formData.Current_CTC_lakhs || "0";
        const currentCtcThousands = formData.Current_CTC_thousands || "0";
        const expectedCtcLakhs = formData.Expected_CTC_lakhs || "0";
        const expectedCtcThousands = formData.Expected_CTC_thousands || "0";
        
        const formattedCurrentCTC = `${currentCtcLakhs} lakh ${currentCtcThousands} thousand`;
        const formattedExpectedCTC = `${expectedCtcLakhs} lakh ${expectedCtcThousands} thousand`;
    
        if(valid){

    const formDataToSend = new FormData();

    formDataToSend.append('Name', formData.Name);
    formDataToSend.append('Email', formData.Email);
    formDataToSend.append('Post', formData.Post);
    formDataToSend.append('Message', formData.Message);
    formDataToSend.append('Phone', formData.Phone);

    if (experienceLevel === 'experienced') {
        formDataToSend.append('Current_CTC', `${currentCtcLakhs * 100000 + currentCtcThousands * 1000}`);
            formDataToSend.append('Expected_CTC', `${expectedCtcLakhs * 100000 + expectedCtcThousands * 1000}`);
    } else {
        formDataToSend.append('Current_CTC', '');
        formDataToSend.append('Expected_CTC', '');
    }

    formDataToSend.append('ExperienceLevel', experienceLevel);

    if (fileInput) {
        formDataToSend.append('UploadFile', fileInput);
    }
 
   
    
    
    fetch(URL, {
        method: 'POST',
        body: formDataToSend
    })
      .then(response => {
        if (!response.ok) {
          throw new Error(`Failed to send email: ${response.statusText}`);
        } 
        return response.json();
      })
      .then(() => {
          alert('Mail sent successfully');
          if (formRef.current) {
            formRef.current.reset();
          }
          setCaptchaInput('');
          setErrors({});
          generateCaptcha();

        })
      .catch(error => {
          alert('Failed to send email. Please try again later.');
      });
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }

      setFormData({ 
        Name: '',
        Email: '',
        Phone: '',
        Post:'',
        Message: '',
        Current_CTC: '',
        Expected_CTC:''
      });
      setExperienceLevel('');
      setErrors('');
      setCaptchaInput('');
      generateCaptcha();

        }else{
            setErrors(newErrors);
        }
        
    }

    return(
<>

<form id="careerform" onSubmit={handleSubmit} ref={formRef} noValidate>
                            <div className="row">
                                <div className="col-md-6 mb-2">
                                    <label htmlFor="name" className="form-label">Name</label>
                                    <input type="text" className="form-control" id="name" name="Name" value={formData.Name}  onChange={handleChange}/>
                                    {errors.Name &&<small id="nameHelp" className="form-text text-danger">{errors.Name}</small>}
                                </div>
                                <div className="col-md-6 mb-2">
                                    <label htmlFor="email" className="form-label">Email</label>
                                    <input type="text" className="form-control" id="email" name="Email" value={formData.Email} onChange={handleChange}/>
                                    {errors.Email &&<small id="emailHelp" className="form-text text-danger">{errors.Email}</small>}
                                </div>
                                <div className="col-md-6 mb-2">
                                    <label htmlFor="phone" className="form-label">Phone</label>
                                    <input type="tel" className="form-control" id="phone" name="Phone" value={formData.Phone} onChange={handleChange}/>
                                    {errors.Phone &&<small id="phoneHelp" className="form-text text-danger">{errors.Phone}</small>}
                                </div>
                                <div className="col-md-6 mb-2">
                                    <label htmlFor="post" className="form-label">Post</label>
                                    <input type="text" className="form-control" id="post" name="Post" value={formData.Post}  onChange={handleChange}/>
                                </div>
                        
                        
                                <div className="col-md-6 mb-2" id="radioDiv">
                                    <input
                                      type="radio"
                                      id="fresher"
                                      value="fresher"
                                      name='Experience'
                                      checked={experienceLevel === 'fresher'}
                                      onChange={handleChange}
                                    />
                                    <label htmlFor="fresher" className="form-label" style={{marginLeft: '1%'}}>Fresher</label>
                                    <input
                                      type="radio"
                                      id="experienced"
                                      value="experienced"
                                      name='Experience'
                                      checked={experienceLevel === 'experienced'}
                                      onChange={handleChange}
                                      style={{marginLeft: '2%'}}
                                    />
                                    <label htmlFor="experienced" className="form-label" style={{marginLeft: '1%'}}>Experienced</label>
                                </div>
                                

                                {experienceLevel === 'experienced' && (
                                    <>
                                        <div className="col-md-12 mb-2">
                                            <label htmlFor="currentCtc" className="form-label">Current CTC</label>
                                            <div className="row">
                                                <span className="col-md-6 d-flex align-items-center">
                                                    <select name="Current_CTC_lakhs" className="form-control" value={formData.Current_CTC.currentCtcLakhs} onChange={handleChange}>
                                                        <option value="1">1</option>
                                                        <option value="2">2</option>
                                                        <option value="3">3</option>
                                                        <option value="4">4</option>
                                                        <option value="5">5</option>
                                                    </select>
                                                    <span> /lakhs</span>
                                                </span>
                                                <span className="col-md-6 d-flex align-items-center">
                                                    <select name="Current_CTC_thousands" className="form-control d-inline-block"  onChange={handleChange}>
                                                        <option value="10">10</option>
                                                        <option value="20">20</option>
                                                        <option value="30">30</option>
                                                        <option value="40">40</option>
                                                        <option value="50">50</option>
                                                    </select>
                                                    <span> /thousands</span>
                                                </span>
                                            </div>
                                        </div>
                                        <div className="col-md-12 mb-2">
                                            <label htmlFor="expectedCtc" className="form-label">Expected CTC</label>
                                            <div className="row">
                                                <span className="col-md-6 d-flex align-items-center">
                                                    <select name="Expected_CTC_lakhs" className="form-control"  onChange={handleChange}>
                                                        <option value="1">1</option>
                                                        <option value="2">2</option>
                                                        <option value="3">3</option>
                                                        <option value="4">4</option>
                                                        <option value="5">5</option>
                                                    </select>
                                                    <span> /lakhs</span>
                                                </span>
                                                <span className="col-md-6 d-flex align-items-center">
                                                    <select name="Expected_CTC_thousands" className="form-control d-inline-block"  onChange={handleChange}>
                                                        <option value="10">10</option>
                                                        <option value="20">20</option>
                                                        <option value="30">30</option>
                                                        <option value="40">40</option>
                                                        <option value="50">50</option>
                                                    </select>
                                                    <span> /thousands</span>
                                                </span>
                                            </div>
                                        </div>
                                    </>
                                )}

                                <div className="col-md-12 mb-2">
                                    <label htmlFor="about_yourself" className="form-label">Few words about yourself</label>
                                    <textarea name="Message" id="about_yourself" cols="30" rows="3" className="form-control" value={formData.Message} onChange={handleChange}></textarea>
                                </div>
                                <div className="col-md-12 mb-2">
                                    <label htmlFor="cv" className="form-label">Attach CV</label>
                                    <input type="file" className="form-control" id="cv" name="cv" onChange={handleChange} ref={fileInputRef}/>
                                    {errors.cv && <small id="cvHelp" className="form-text text-danger">{errors.cv}</small>}
                                </div>
                            </div>

                            <div className="col-md-12 my-3" id="captcha-container">
                                <div>
                                    <label htmlFor="captcha" className="form-label mb-1">Enter Captcha:</label>
                                    {/* <span id="captcha-display" className="captcha-display py-2 mb-1">{captcha}</span> */}
                                    <canvas ref={canvasRef} width={200} height={40}></canvas>
                                </div>
                                <div>
                                    <input type="text" className="form-control w-50" id="captcha-input" name="captcha" value={captchaInput} onChange={handleChange}/>
                                    <button id="refreshCaptcha" className="btn btn-secondary" onClick={generateCaptcha}>Refresh CAPTCHA</button>
                                </div>
                                {errors.captcha &&<small id="captchaHelp" className="form-text text-danger py-2">{errors.captcha}</small>}
                            </div>

                            <button type="submit" className="custom-btn w-100" name="submit" value="submit">Submit</button>
                        </form>
                        <p className="my-2 text-center"><strong>-- OR --</strong></p>
                        <div className="drop-resume">
                            <h5 className="mr-2 mb-0">Drop your resume at : </h5>
                            <Link to="mailto:careers@stamenssoftware.com"><i className="fa fa-envelope"></i> careers@stamenssoftware.com</Link>
                        </div>

</>
    )
}

export default CareerForm;