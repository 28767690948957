import React from 'react';
import Footer from '../components/footer';
import { Helmet } from 'react-helmet';
import Banner from '../components/banner';
import ServiceComponent from '../components/servicecomponent';
import './seoservice.css';
import Process from '../components/process';
import LetsCode from '../components/letscode';

const deg=`${process.env.PUBLIC_URL}/images/clients/66deg-client.png`;
const cabi=`${process.env.PUBLIC_URL}/images/clients/cabi.png`;
const proIt=`${process.env.PUBLIC_URL}/images/clients/pro-it.png`;
const Scadea=`${process.env.PUBLIC_URL}/images/clients/scadea-client.png`;
const nextLoop=`${process.env.PUBLIC_URL}/images/clients/next-loop.png`

const elegantFemale = `${process.env.PUBLIC_URL}/images/elegant-female.png`;
const customSoftwareDevelopment = `${process.env.PUBLIC_URL}/images/icons/custom-software-development.png`;


const bannerDetails =
  {
      title: 'Our Services',
      subTitle: 'Google Ads Service',
      description: "Whether you're looking to increase website traffic, boost online sales, or build brand awareness, our Google Ads experts will craft strategies designed to meet your specific goals."
  }

  const Services = [
    {
      image : customSoftwareDevelopment,
      heading : "Not Enough Sales or Leads"
    },
    {
      image : customSoftwareDevelopment,
      heading : "Rising Google Ads Expenses"
    },
    {
      image : customSoftwareDevelopment,
      heading : "Poor Agency Relationship"
    },
    {
      image : customSoftwareDevelopment,
      heading : "Declining ROI"
    },
    {
      image : customSoftwareDevelopment,
      heading : "Shopping Campaigns Not Effective"
    },
    {
      image : customSoftwareDevelopment,
      heading : "Below Average Quality Scores"
    },
    {
      image : customSoftwareDevelopment,
      heading : "Poor Campaign Performance"
    },
    {
      image : customSoftwareDevelopment,
      heading : "Low Conversion Rates"
    },
    {
      image : customSoftwareDevelopment,
      heading : "Wasted Advertising Budget"
    }
    ]

    const processCard = [
      {
          heading: 'Our Google AdWords Service',
          subheading: "Whether you're aiming to increase brand awareness, drive qualified traffic, or boost online sales, our team of certified experts will craft customized strategies tailored to your goals.",
          processItems: [
              {
                  num: '01',
                  image: customSoftwareDevelopment,
                  contentheading: 'Campaign Management',
              },
              {
                  num: '02',
                  image: customSoftwareDevelopment,
                  contentheading: 'Best-in-Class Targeting',
              },
              {
                num: '03',
                image: customSoftwareDevelopment,
                contentheading: 'Reporting & Analytics',
              }
          ]
      },
      {
  
      }
  ]

function GooleAds() {
  return (
    <>
    <Helmet>
    <title> Google Ads Service| Digital Marketing | Stamens Software</title>
    </Helmet>

    <Banner details={bannerDetails} descriptionClass={'text-center fs-5'}/>

    <section class="Challenge bg-white">
            <div class="container">
                <h2 class="head text-center">
                Tired of Dealing With Google Advertising Challenges?
                </h2>
                <p class="text-center fs-5 pb-4">Managing Google Ads campaigns can be overwhelming, especially with the ever-evolving landscape of digital marketing. From keyword bidding wars to ad copy optimization, ensuring your ads reach the right audience while staying within budget is no easy task.</p>
                <div class="row justify-content-center gy-4">
                {Services.map((card, index) => (
                  <ServiceComponent key={index} serviceprops={card} h4={'h3'} />
                ))}
                </div>
            </div>
        </section>

        {processCard.map((item, index) => (
        <Process 
          key={index}
          heading={item.heading}
          subheading={item.subheading}
          processItems={item.processItems}
          parentClass={'seo-include'}
          childClass={'row process'}
          childClassA={'col-lg-4 point'}
        />
      ))}

      <LetsCode />

      <section class="technologie-seo">
            <div class="container">
                <h3 class="head">
                    <span>Our Clients</span>
                    Real Results for Real SEO Clients
                </h3>
                <div class="row gy-4 justify-content-center">
                    <div class="col-lg-3 col-md-4">
                        <div class="icons">
                            <img src={deg} alt="client 1" class="img-fluid" width="237" height="103" />
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-4">
                        <div class="icons">
                            <img src={cabi} alt="client 2" class="img-fluid" width="237" height="103" />
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-4">
                        <div class="icons">
                            <img src={proIt} alt="client 3" class="img-fluid" width="237" height="103" />
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-4">
                        <div class="icons">
                            <img src={Scadea} alt="client 4" class="img-fluid" width="237" height="103" />
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-4">
                        <div class="icons">
                            <img src="https://www.stamenssoftware.com/images/clients/techolution-client.png" alt="client 5" class="img-fluid" width="237" height="103" />
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-4">
                        <div class="icons">
                            <img src={nextLoop} alt="client 6" class="img-fluid" width="237" height="103" />
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="partner bg-white">
            <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-lg-5 pt-4">
                            <img src={elegantFemale} alt="vision" class="img-fluid rounded" style={{height: '100%', objectFit: 'cover'}} />
                        </div>
                        <div class="col-lg-7">
                            <h4 class="head">
                                Partner with our client-centered team for top-quality software solutions
                            </h4>
                            <p class="mt-n2 mb-25">Looking for top-notch software developers? Look no further! Our team of certified experts is dedicated to providing a client-centered approach and delivering the highest quality software using the software development life cycle (SDLC). Our team is comprised of experienced programmers who are known for their outstanding consulting services and for writing clean code.</p>
                            <p class="mt-n2 mb-25">Trust us, one of the most reliable software outsourcing companies, to make your mission-critical project a success by hiring one of our talented software developers.</p>
                            <a href="#" class="hire-btn">Hire Us Now</a>
                        </div>
                    </div>
                </div>
        </section>

        <section class="our-company">
            <div class="container">
                <h4 class="head">
                    <span>Why Stamens Software</span>
                    We are one of the best IT companies?
                </h4>
                <div class="row gy-4">
                    <div class="col-lg-3 col-md-4">
                        <div class="icons">
                            <div class="icon"><img src={customSoftwareDevelopment} alt="Custom Software Development" class="img-fluid" /></div>
                            <p>15+ Years in Industry</p>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-4">
                        <div class="icons">
                            <div class="icon"><img src={customSoftwareDevelopment} alt="Custom Software Development" class="img-fluid" /></div>
                            <p>Source Code Delivery to Clients</p>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-4">
                        <div class="icons">
                            <div class="icon"><img src={customSoftwareDevelopment} alt="Custom Software Development" class="img-fluid" /></div>
                            <p>Microsoft 365, Azure</p>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-4">
                        <div class="icons">
                            <div class="icon"><img src={customSoftwareDevelopment} alt="Custom Software Development" class="img-fluid" /></div>
                            <p>Less than 24 hrs Guaranteed Response</p>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-4">
                        <div class="icons">
                            <div class="icon"><img src={customSoftwareDevelopment} alt="Custom Software Development" class="img-fluid" /></div>
                            <p>Certified Developers</p>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-4">
                        <div class="icons">
                            <div class="icon"><img src={customSoftwareDevelopment} alt="Custom Software Development" class="img-fluid" /></div>
                            <p>Dedicated Project Manager</p>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-4">
                        <div class="icons">
                            <div class="icon"><img src={customSoftwareDevelopment} alt="Custom Software Development" class="img-fluid" /></div>
                            <p>Clients: USA, UK, Australia, etc.</p>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-4">
                        <div class="icons">
                            <div class="icon"><img src={customSoftwareDevelopment} alt="Custom Software Development" class="img-fluid" /></div>
                            <p>Handle Tight Deadlines</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>     
   
    <Footer />
    </>
  )
}

export default GooleAds;
